import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout";
import { connect } from 'react-redux'
import { getSidebarSelectedKey, getSidebarEntry } from "../store/selectors";
import "../assets/css/bootstrap.min.css";
import "../assets/css/main.css";
import Img from "gatsby-image"

function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html, id } = markdownRemark

  return (
    <Layout>
      <section className="mt-2 text-center">
        <Img fluid={frontmatter.coverPhoto.childImageSharp.fluid} className="post-img" alt="..." />
      </section>

      <section style={{paddingTop:"40px"}}>
          <div className="container">
            <h2>{frontmatter.title}</h2>
            <p className="card-text"><small className="text-muted">{frontmatter.date}</small></p>
            <br />
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
      </section>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps) (Template)

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path} }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        coverPhoto {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`